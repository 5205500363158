<template>
  <div>
    <b-card-code title="Update Seller Status" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col sm="12">
              <div>
                <!-- Row Loop -->
                <!-- Name english -->
                <b-col md="6">
                  <b-form-group label="Name English" label-for="Name English">
                    <validation-provider
                      #default="{ errors }"
                      name="Name English"
                      rules="required"
                    >
                      <b-form-input
                        id="Name-English"
                        v-model="status.name.en"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Name Arabic -->
                <b-col md="6">
                  <b-form-group label="Name Arabic" label-for="Name Arabic">
                    <validation-provider
                      #default="{ errors }"
                      name="Name Arabic"
                      rules="required"
                    >
                      <b-form-input
                        id="Name-Arabic"
                        v-model="status.name.ar"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </div>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import { heightTransition } from "@core/mixins/ui/transition";

import { required, } from "@validations";

export default {
  components: {

    BCardCode,
  },  
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      id: 0,
      status: {
        name: "",
      },
      errors_back: [],
      required,
    };
  },
  created() {
    // request status data
    this.id = this.$route.params.id;
    axios
      .get("status/" + this.id)
      .then((result) => {
        const data = result.data.data;
        this.status = data;
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
  methods: {
    // update function to category
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios
            .put("status/" + this.status.id, this.status)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
